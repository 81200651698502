import * as React from 'react'
import { Layout } from '../components'

const Page: React.FC = () => {
  return (
    <Layout>
      <h1>Ups</h1>
      <p>We have no clue about the page you requested</p>
    </Layout>
  )
}
export default Page
